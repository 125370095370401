import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate, useRoutes } from "react-router-dom";
import { useGetMeQuery } from "./api/user.api";
import useMyContext from "./hooks/useMyContext";
import EmailConfigurationScreen from "./screens/EmailConfigurationScreen/EmailConfigurationScreen";
import LayoutScreen from "./screens/LayoutScreen/LayoutScreen";
import LoginScreen from "./screens/LoginScreen";
import NoContextScreen from "./screens/NoContextScreen";
import UsersInvitedListScreen from "./screens/UsersInvitedListScreen/UsersInvitedListScreen";
import UsersInvitedMultipleScreen from "./screens/UsersInvitedMultipleScreen/UsersInvitedMultipleScreen";
import UsersInvitedSoloScreen from "./screens/UsersInvitedSoloScreen/UsersInvitedSoloScreen";

function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const myContext = useMyContext();

  const { data: me, isLoading: isLoading1 } = useGetMeQuery({ ...myContext });
  // const { data: eventUser, isLoading: isLoading2 } = useGetUserEventQuery({ ...myContext, userId: me?.id });

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const newToken = search.get("token");

    if (newToken && localStorage.getItem("token") !== newToken) {
      localStorage.setItem("token", newToken);
      navigate(0);
    }
  }, [location.search, navigate]);

  if (isLoading1) {
    return null;
  }

  if (myContext && !me && !isLoading1) {
    return <Navigate to="../login" replace />;
  }

  // if (!eventUser?.UserEvent?.isExhibitor) {
  //   return <NotInvitedScreen />;
  // }

  return children;
}

function Routes() {
  const element = useRoutes([
    {
      path: "/:context/",
      element: <LayoutScreen />,
      children: [
        {
          path: "",
          element: (
            <ProtectedRoute>
              <UsersInvitedListScreen />
            </ProtectedRoute>
          ),
        },
        {
          path: "email-configuration",
          element: (
            <ProtectedRoute>
              <EmailConfigurationScreen />
            </ProtectedRoute>
          ),
        },
        {
          path: "invite-solo",
          element: (
            <ProtectedRoute>
              <UsersInvitedSoloScreen />
            </ProtectedRoute>
          ),
        },
        {
          path: "invite-multiple",
          element: (
            <ProtectedRoute>
              <UsersInvitedMultipleScreen />
            </ProtectedRoute>
          ),
        },
        { path: "login", element: <LoginScreen /> },
      ],
    },
    { path: "*", element: <NoContextScreen /> },
  ]);

  return element;
}

export default Routes;
