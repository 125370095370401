import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

function BackButton() {
  const navigate = useNavigate();

  return (
    <Button type="primary" onClick={() => navigate(-1)}>
      Retour
    </Button>
  );
}

export default BackButton;
