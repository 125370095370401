import { useParams } from "react-router-dom";
import { useGetContextQuery } from "../api/api";

const useSettings = () => {
  const { context } = useParams();
  const { data } = useGetContextQuery(context);

  if (data) {
    return data.settings;
  }

  return null;
};

export default useSettings;
