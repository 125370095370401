import React from "react";
import { Navigate } from "react-router-dom";
import { useGetMeQuery } from "../../api/user.api";
import useMyContext from "../../hooks/useMyContext";
import LoginForm from "./LoginForm";

function LoginScreen() {
  const myContext = useMyContext();
  const { data: me, isLoading: isLoading1 } = useGetMeQuery({ ...myContext });

  if (myContext && me && !isLoading1) {
    return <Navigate to="../" replace />;
  }

  return <LoginForm />;
}

export default LoginScreen;
