import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getApiUrl = () => {
  const host = window.location.hostname;
  switch (host) {
    case "localhost":
      return "http://localhost:3001";
    case host.includes("dev"):
      return "https://api.dev.gayakoa.com";
    default:
      return "https://api.gayakoa.com";
  }
};

const baseUrl = getApiUrl();

console.log(process.env);

const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
      headers.set("authorization", `Bearer ${localStorage.getItem("token")}`);
      return headers;
    },
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getContext: builder.query({
      query: (context) => `/context/${context}`,
      transformResponse: (response) => response.data,
    }),
    createPUTUrlSigned: builder.mutation({
      query: ({ domainId, contentType, isPublic = false }) => ({
        url: `/domains/${domainId}/files`,
        method: "POST",
        body: { contentType, isPublic },
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export default api;

export const { useGetContextQuery, useCreatePUTUrlSignedMutation } = api;
