import { useParams } from "react-router-dom";
import { useGetContextQuery } from "../api/api";

const useMyContext = () => {
  const { context } = useParams();
  const { data } = useGetContextQuery(context);

  if (data) {
    return { eventId: data.id, domainId: data.domainId };
  }

  return null;
};

export default useMyContext;
