import { Alert, Form, Input } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../api/user.api";
import { ButtonBlock, Center, FormBlock } from "./LoginForm.styles";

function LoginForm() {
  const navigate = useNavigate();

  const [error, setError] = useState();

  const [login, { isLoading }] = useLoginMutation();

  const onSubmit = async (values) => {
    setError(null);

    try {
      const { token } = await login(values).unwrap();
      localStorage.setItem("token", token);
      navigate(0);
    } catch (err) {
      setError(err?.data?.message || "Une erreur est survenue");
    }
  };

  return (
    <Center>
      <FormBlock layout="vertical" onFinish={onSubmit}>
        <Form.Item label="Email" name="email" rules={[{ required: true, message: "L'email est obligatoire" }]}>
          <Input type="email" />
        </Form.Item>

        <Form.Item label="Mot de passe" name="password" rules={[{ required: true, message: "Le mot de passe est obligatoire" }]}>
          <Input.Password />
        </Form.Item>

        {error && (
          <Form.Item>
            <Alert message={error} type="error" showIcon />
          </Form.Item>
        )}

        <ButtonBlock type="primary" htmlType="submit" loading={isLoading}>
          Connexion
        </ButtonBlock>
      </FormBlock>
    </Center>
  );
}

export default LoginForm;
