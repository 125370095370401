import { Spin } from "antd";
import React from "react";
import { Center } from "./MyLoading.styles";

function MyLoading() {
  return (
    <Center>
      <Spin size="large" />
    </Center>
  );
}

export default MyLoading;
