import api from "./api";

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserEvent: builder.query({
      query: ({ domainId, eventId, userId }) => `/domains/${domainId}/events/${eventId}/users/${userId}`,
      transformResponse: (response) => response.data,
    }),
    login: builder.mutation({
      query: ({ email, password }) => ({ url: `/authentications/login`, method: "POST", body: { email, password } }),
      transformResponse: (response) => response.data,
      invalidatesTags: [{ type: "User", id: "me" }],
    }),
    getMe: builder.query({
      query: ({ domainId }) => `/domains/${domainId}/users/me`,
      transformResponse: (response) => response.data,
      providesTags: [{ type: "User", id: "me" }],
    }),
  }),
});

export const { useLoginMutation, useLazyGetMeQuery, useLazyGetUserEventQuery, useGetMeQuery, useGetUserEventQuery } = userApi;
