import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Space, Table } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetEmailConfigurationQuery } from "../../api/email.api";
import { useGetUsersInvitedByMeQuery } from "../../api/eventUsers.api";
import useMyContext from "../../hooks/useMyContext";
import useSettings from "../../hooks/useSettings";

function UsersInvitedListScreen() {
  const navigate = useNavigate();
  const myContext = useMyContext();
  const settings = useSettings();
  const { state } = useLocation();

  const { data: users } = useGetUsersInvitedByMeQuery({ ...myContext });
  const { data: config } = useGetEmailConfigurationQuery({ ...myContext });

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <div dangerouslySetInnerHTML={{ __html: settings?.FO_INVITATIONS_LIST_USERS_HTML?.html }} />
      {state?.count && (
        <Alert
          type="success"
          showIcon
          message={`Vous avez importé ${state.total || 0} contacts sur ${
            state.count
          } contacts. Si vous constatez une différence, elle est dû à des adresses emails erronées.`}
        />
      )}
      <Space>
        <Button type="primary" onClick={() => navigate("../email-configuration")}>
          Configuration email
        </Button>
        <Button type="primary" onClick={() => navigate("../invite-solo")} disabled={!config}>
          Inviter un contact
        </Button>
        <Button type="primary" onClick={() => navigate("../invite-multiple")} disabled={!config}>
          Inviter des contacts (excel)
        </Button>
      </Space>
      {users?.length > 0 ? (
        <Table
          rowKey="id"
          columns={[
            { title: "Prénom", dataIndex: ["user", "firstName"], sorter: (a, b) => a.user.firstName.localeCompare(b.user.firstName) },
            { title: "Nom", dataIndex: ["user", "lastName"], sorter: (a, b) => a.user.lastName.localeCompare(b.user.lastName) },
            {
              title: "Email",
              dataIndex: ["user", "auth", "email"],
              sorter: (a, b) => a.user.auth?.email.localeCompare(b.user.auth?.email),
            },
            {
              title: "Inscrit",
              dataIndex: "registeredFrom",
              render: (registered) =>
                registered ? <CheckCircleOutlined style={{ color: "green" }} /> : <CloseCircleOutlined style={{ color: "red" }} />,
              sorter: (a, b) => (b.registeredFrom && !a.registeredFrom ? 1 : -1),
            },
          ]}
          dataSource={users}
        />
      ) : (
        <Alert type="info" showIcon message="Vous n’avez pas encore envoyé d’invitation" />
      )}
    </div>
  );
}

export default UsersInvitedListScreen;
