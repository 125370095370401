import { Alert, Form, Input } from "antd";
import React, { useState } from "react";
import {
  useCreateEmailConfigurationMutation,
  useGetEmailConfigurationQuery,
  useSendEmailMutation,
  useUpdateEmailConfigurationMutation,
} from "../../api/email.api";
import { useGetMeQuery } from "../../api/user.api";
import BackButton from "../../components/BackButton/BackButton";
import UploadImage from "../../components/UploadImage/UploadImage";
import useMyContext from "../../hooks/useMyContext";
import useSettings from "../../hooks/useSettings";
import useUploadFile from "../../hooks/useUploadFile";
import { ButtonBlock, Center, FormBlock, LeftBlock, RightBlock } from "./UsersInvitedSoloScreen.styles";

function EmailConfigurationScreen() {
  const [error, setError] = useState();

  const myContext = useMyContext();
  const [uploadFile] = useUploadFile();
  const settings = useSettings();

  const { data: config, isLoading } = useGetEmailConfigurationQuery({ ...myContext });
  const [createConfig, { isLoading: isCreating, isSuccess: isCreated }] = useCreateEmailConfigurationMutation();
  const [updateConfig, { isLoading: isUpdating, isSuccess: isUpdated }] = useUpdateEmailConfigurationMutation();
  const [sendEmail, { isLoading: isSending, isSuccess: isSended }] = useSendEmailMutation();
  const { data: me } = useGetMeQuery({ ...myContext });

  const onSubmit = async (values) => {
    try {
      const finalValues = { ...values };

      if (finalValues.logo2) {
        const { url } = await uploadFile(finalValues.logo2, true);
        finalValues.logoUrl = url;
        finalValues.logo2 = undefined;
      }

      if (config) {
        updateConfig({ ...myContext, body: { ...config, ...finalValues } });
      } else {
        createConfig({ ...myContext, body: finalValues });
      }
    } catch (err) {
      setError(err?.data?.message || "Une erreur est survenue");
    }
  };

  if (isLoading) return null;

  return (
    <div>
      <BackButton />
      <Center>
        <LeftBlock>
          <div dangerouslySetInnerHTML={{ __html: settings?.FO_INVITATIONS_CONFIGURATION_EMAIL_HTML?.html }} />
        </LeftBlock>
        <RightBlock>
          <FormBlock layout="vertical" onFinish={onSubmit} initialValues={config}>
            <Form.Item label="Logo" name="logo2">
              <UploadImage defaultUrl={config?.logoUrl} />
            </Form.Item>

            <Form.Item
              label="Raison sociale"
              name="socialReason"
              rules={[{ required: true, message: "La raison sociale est obligatoire" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Numéro de stand" name="standNumber">
              <Input />
            </Form.Item>

            <Form.Item label="Texte libre" name="text">
              <Input.TextArea rows={3} />
            </Form.Item>

            {error && (
              <Form.Item>
                <Alert message={error} type="error" showIcon />
              </Form.Item>
            )}

            <Form.Item>
              <ButtonBlock type="primary" htmlType="submit" loading={isLoading || isUpdating || isCreating}>
                Enregistrer
              </ButtonBlock>
            </Form.Item>

            {(isCreated || isUpdated) && (
              <Form.Item>
                <Alert
                  type="info"
                  message="Les réglages de votre invitation sont bien enregistrés, vous pouvez vous envoyer une invitation pour tester et vérifier, cliquez sur « Tester l’email », vous recevrez un message de test !"
                  showIcon
                />
              </Form.Item>
            )}

            <Form.Item>
              <ButtonBlock
                onClick={() =>
                  sendEmail({
                    ...myContext,
                    accountId: settings?.FO_INVITATIONS_EMAIL_ACCOUNT_ID,
                    templateId: settings?.FO_INVITATIONS_EMAIL_TEMPLATE_ID,
                    usersId: [me?.id],
                    variables: {
                      logoUrl: " ",
                      socialReason: " ",
                      standNumber: " ",
                      text: " ",
                      ...config,
                    },
                  })
                }
                loading={isSending}
                disabled={!config}
              >{`Tester l'email`}</ButtonBlock>
            </Form.Item>

            {isSended && (
              <Form.Item>
                <Alert type="info" message="Votre message a bien été envoyé" showIcon />
              </Form.Item>
            )}
          </FormBlock>
        </RightBlock>
      </Center>
    </div>
  );
}

export default EmailConfigurationScreen;
