import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import React from "react";

function UploadFile({ onChange }) {
  return (
    <Upload
      beforeUpload={() => false}
      maxCount={1}
      listType="picture-card"
      showUploadList
      onChange={(info) => {
        onChange(info.file);
      }}
    >
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    </Upload>
  );
}

export default UploadFile;
