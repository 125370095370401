import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Form, Modal, Select } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import { useCreateAndInviteUserMutation } from "../../api/eventUsers.api";
import BackButton from "../../components/BackButton/BackButton";
import UploadFile from "../../components/UploadImage/UploadFile";
import useMyContext from "../../hooks/useMyContext";
import useSettings from "../../hooks/useSettings";
import { ButtonBlock, Center, FormBlock, LeftBlock, RightBlock } from "./UsersInvitedSoloScreen.styles";

const { confirm } = Modal;

function splitArrayIntoChunks(array, chunkSize) {
  const chunks = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  return chunks;
}

function UsersInvitedMultipleScreen() {
  const [error, setError] = useState();
  const [data, setData] = useState();

  const navigate = useNavigate();
  const myContext = useMyContext();
  const settings = useSettings();

  const [createAndInviteUser, { isLoading }] = useCreateAndInviteUserMutation();

  const onSubmit = async (values) => {
    setError(null);

    try {
      const array = await readXlsxFile(values.excel);
      array.shift();

      const users = array.map((a, i) => {
        const finalA = {};

        const email = a[Number(values.email)];

        if (email) {
          finalA.email = email;
        } else {
          throw Error(`La ligne ${i + 1} ne contient pas d'email`);
        }

        const firstName = a[Number(values.firstName)];

        if (firstName) {
          finalA.firstName = firstName;
        } else {
          throw Error(`La ligne ${i + 1} ne contient pas de prénom`);
        }

        const lastName = a[Number(values.lastName)];

        if (lastName) {
          finalA.lastName = lastName;
        } else {
          throw Error(`La ligne ${i + 1} ne contient pas de nom`);
        }

        return finalA;
      });

      const chunks = splitArrayIntoChunks(users, 500);

      const results = await Promise.all(chunks.map((m) => createAndInviteUser({ ...myContext, users: m }).unwrap()));
      const total = results.reduce((prev, curr) => prev + curr, 0);

      // await createAndInviteUser({ ...myContext, users }).unwrap();
      navigate("../", { state: { count: users.length, total } });
    } catch (err) {
      setError(err?.data?.message || err?.message || "Une erreur est survenue");
    }
  };

  return (
    <div>
      <BackButton />
      <Center>
        <LeftBlock>
          <div dangerouslySetInnerHTML={{ __html: settings?.FO_INVITATIONS_INVITE_MULTIPLE_HTML?.html }} />
        </LeftBlock>
        <RightBlock>
          <FormBlock
            layout="vertical"
            onFinish={(values) => {
              confirm({
                title: `Vous allez envoyer l'invitation à tous les contacts de votre fichier excel`,
                icon: <ExclamationCircleOutlined />,
                okText: `Oui, j'envoie les invitations`,
                cancelText: "Annuler",
                onOk: () => onSubmit(values),
              });
            }}
            onValuesChange={async (values) => {
              if (values.excel) {
                const array = await readXlsxFile(values.excel);
                setData(array);
              }
            }}
          >
            <Form.Item>
              <Alert message="Chargez votre fichier excel et affectez les colonnes ci-dessous :" type="info" />
            </Form.Item>

            <Form.Item label="Excel" name="excel" rules={[{ required: true, message: "Le fichier est obligatoire" }]}>
              <UploadFile />
            </Form.Item>

            {data && (
              <Form.Item>
                <Alert
                  message={`Votre fichier contient ${data.length - 1} contacts, veuillez sélectionner les champs d’information ci-dessous`}
                  type="info"
                />
              </Form.Item>
            )}

            {Array.isArray(data) && data.length > 0 && (
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsValue }) => {
                  const { email, firstName, lastName } = getFieldsValue();

                  const options = data[0].map((m, i) => ({
                    label: m,
                    value: i,
                    disabled: firstName === i || lastName === i || email === i,
                  }));

                  return (
                    <>
                      <Form.Item
                        label="Choisir la colonne de l'email"
                        name="email"
                        rules={[{ required: true, message: "Ce choix est obligatoire" }]}
                      >
                        <Select options={options} allowClear />
                      </Form.Item>

                      <Form.Item
                        label="Choisir la colonne du prénom"
                        name="firstName"
                        rules={[{ required: true, message: "Ce choix est obligatoire" }]}
                      >
                        <Select options={options} allowClear />
                      </Form.Item>

                      <Form.Item
                        label="Choisir la colonne du nom"
                        name="lastName"
                        rules={[{ required: true, message: "Ce choix est obligatoire" }]}
                      >
                        <Select options={options} allowClear />
                      </Form.Item>
                    </>
                  );
                }}
              </Form.Item>
            )}

            {error && (
              <Form.Item>
                <Alert message={error} type="error" showIcon />
              </Form.Item>
            )}

            <Form.Item>
              <ButtonBlock type="primary" htmlType="submit" loading={isLoading}>
                Inviter
              </ButtonBlock>
            </Form.Item>
          </FormBlock>
        </RightBlock>
      </Center>
    </div>
  );
}

export default UsersInvitedMultipleScreen;
