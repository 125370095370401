import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import React, { useCallback, useState } from "react";

function UploadImage({ onChange, defaultUrl }) {
  const [file, setFile] = useState();

  const Preview = useCallback(() => {
    if (file) return <img alt="logo" src={URL.createObjectURL(file)} style={{ width: "100%" }} />;
    if (defaultUrl) return <img alt="logo" src={defaultUrl} style={{ width: "100%" }} />;
    return (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Charger</div>
      </div>
    );
  }, [defaultUrl, file]);

  return (
    <Upload
      beforeUpload={() => false}
      maxCount={1}
      listType="picture-card"
      showUploadList={false}
      onChange={(info) => {
        setFile(info.file);
        onChange(info.file);
      }}
    >
      <Preview />
    </Upload>
  );
}

export default UploadImage;
