import api from "./api";

export const eventUsersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsersInvitedByMe: builder.query({
      query: ({ domainId, eventId }) => `/v2/domains/${domainId}/events/${eventId}/users/invited-by-me`,
      transformResponse: (response) => response.data,
      providesTags: ["UserInvited"],
    }),
    createAndInviteUser: builder.mutation({
      query: ({ domainId, eventId, users }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/users/create-invite`,
        method: "POST",
        body: { users },
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["UserInvited"],
    }),
  }),
});

export const { useGetUsersInvitedByMeQuery, useCreateAndInviteUserMutation } = eventUsersApi;
