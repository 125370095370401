import api from "./api";

export const emailApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEmailConfiguration: builder.query({
      query: ({ domainId, eventId }) => `/v2/domains/${domainId}/events/${eventId}/email-config`,
      transformResponse: (response) => response.data,
      providesTags: ["EmailConfiguration"],
    }),
    createEmailConfiguration: builder.mutation({
      query: ({ domainId, eventId, body }) => ({ url: `/v2/domains/${domainId}/events/${eventId}/email-config`, method: "POST", body }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["EmailConfiguration"],
    }),
    updateEmailConfiguration: builder.mutation({
      query: ({ domainId, eventId, body }) => ({ url: `/v2/domains/${domainId}/events/${eventId}/email-config`, method: "PUT", body }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["EmailConfiguration"],
    }),
    sendEmail: builder.mutation({
      query: ({ domainId, eventId, accountId, templateId, usersId, variables }) => ({
        url: `/domains/${domainId}/events/${eventId}/emails/send`,
        method: "POST",
        body: { accountId, templateId, usersId, variables },
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetEmailConfigurationQuery,
  useCreateEmailConfigurationMutation,
  useUpdateEmailConfigurationMutation,
  useSendEmailMutation,
} = emailApi;
