import { useCreatePUTUrlSignedMutation } from "../api/api";
import useMyContext from "./useMyContext";

const uploadToS3 = (signedUrl, file) => fetch(signedUrl, { method: "PUT", body: file, headers: { "Content-Type": file.type } });

const useUploadFile = () => {
  const myContext = useMyContext();

  const [createPUTUrlSigned] = useCreatePUTUrlSignedMutation();

  const uploadFile = async (file, isPublic = false) => {
    const { signedUrl, uuid, url } = await createPUTUrlSigned({ ...myContext, contentType: file.type, isPublic }).unwrap();

    await uploadToS3(signedUrl, file);

    return { uuid, url };
  };

  return [uploadFile];
};

export default useUploadFile;
