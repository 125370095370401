import { Alert, Form, Input } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateAndInviteUserMutation } from "../../api/eventUsers.api";
import BackButton from "../../components/BackButton/BackButton";
import useMyContext from "../../hooks/useMyContext";
import useSettings from "../../hooks/useSettings";
import { ButtonBlock, Center, FormBlock, LeftBlock, RightBlock } from "./UsersInvitedSoloScreen.styles";

function UsersInvitedSoloScreen() {
  const [error, setError] = useState();

  const navigate = useNavigate();
  const myContext = useMyContext();
  const settings = useSettings();

  const [createAndInviteUser, { isLoading }] = useCreateAndInviteUserMutation();

  const onSubmit = async (values) => {
    setError(null);

    try {
      await createAndInviteUser({ ...myContext, users: [values] }).unwrap();
      navigate("../", { state: { count: 1 } });
    } catch (err) {
      setError(err?.data?.message || "Une erreur est survenue");
    }
  };

  return (
    <div>
      <BackButton />
      <Center>
        <LeftBlock>
          <div dangerouslySetInnerHTML={{ __html: settings?.FO_INVITATIONS_INVITE_SOLO_HTML?.html }} />
        </LeftBlock>
        <RightBlock>
          <FormBlock layout="vertical" onFinish={onSubmit}>
            <Form.Item label="Email" name="email" rules={[{ required: true, message: "L'email est obligatoire" }]}>
              <Input type="email" />
            </Form.Item>

            <Form.Item label="Prénom" name="firstName" rules={[{ required: true, message: "Le prénom est obligatoire" }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Nom" name="lastName" rules={[{ required: true, message: "Le nom est obligatoire" }]}>
              <Input />
            </Form.Item>

            {error && (
              <Form.Item>
                <Alert message={error} type="error" showIcon />
              </Form.Item>
            )}

            <Form.Item>
              <ButtonBlock type="primary" htmlType="submit" loading={isLoading}>
                Inviter
              </ButtonBlock>
            </Form.Item>
          </FormBlock>
        </RightBlock>
      </Center>
    </div>
  );
}

export default UsersInvitedSoloScreen;
